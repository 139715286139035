import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import api, {
	Credentials,
	EmailConfirm,
	ResetPassword,
	ResetPasswordRequest,
	User,
	UserRegisterData
} from "@s6e/spicify-api-sdk-js";
import { extractErrors } from "store/common";

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: ['User'],
	endpoints: (builder) => ({
		getUser: builder.query<User, void>({
			queryFn: async (_) => {
				try {
					const user = await api.users.getProfile();
					return { data: user };
				} catch (error) {
					return extractErrors(error);
				}
			},
		}),
		login: builder.mutation<User, Credentials>({
			queryFn: async (payload: Credentials) => {
				try {
					await api.login(payload);
					const user = await api.users.getProfile();
					return { data: user };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: 'User' }]
		}),
		initializeResetPasswordFlow: builder.mutation<string, ResetPasswordRequest>({
			queryFn: async (payload: ResetPasswordRequest) => {
				try {
					await api.users.requestResetPassword(payload);
					return { data: payload.email };
				} catch (error) {
					return extractErrors(error);
				}
			}
		}),
		resetPassword: builder.mutation<User, ResetPassword>({
			queryFn: async (payload: ResetPassword) => {
				try {
					let user = await api.users.resetPassword(payload);
					return { data: user };
				} catch (error) {
					return extractErrors(error);
				}
			}
		}),
		confirmEmail: builder.mutation<string, EmailConfirm>({
			queryFn: async (payload: EmailConfirm) => {
				try {
					await api.users.confirmEmail(payload);
					return { data: '' };
				} catch (error) {
					return extractErrors(error);
				}
			}
		}),
		registerUser: builder.mutation<User, UserRegisterData>({
			queryFn: async (payload: UserRegisterData) => {
				try {
					let user = await api.users.register(payload);
					return { data: user };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: 'User' }]
		}),
	}),
});

export const {
	useGetUserQuery,
	useLoginMutation,
	useInitializeResetPasswordFlowMutation,
	useResetPasswordMutation,
	useConfirmEmailMutation,
	useRegisterUserMutation,
} = authApi