import { Invitation, Invitations, InvitationUpdate } from "@s6e/spicify-api-sdk-js";
import api from "@s6e/spicify-api-sdk-js";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { extractErrors } from "store/common";

interface InvitationParams {
	workspaceId: number;
	invitationId: number;
}

interface UpdateInvitationParams {
	workspaceId: number,
	invitationId: number,
	data: InvitationUpdate
}

export const invitationApi = createApi({
	reducerPath: 'invitationApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: ['Invitation'],
	endpoints: (builder) => ({
		getInvitations: builder.query<Invitations, number>({
			queryFn: async (workspaceId) => {
				try {
					const invitations = await api.invitations.getInvitations(workspaceId);
					return { data: invitations }
				} catch (error) {
					return extractErrors(error);
				}
			},
			providesTags: (result = []) => [
				'Invitation',
				...result.map(({ id }) => ({ type: 'Invitation' as const, id }))
			]
		}),
		updateInvitation: builder.mutation<Invitation, UpdateInvitationParams>({
			queryFn: async (params: UpdateInvitationParams) => {
				try {
					const { workspaceId, invitationId, data } = params;
					const invitation = await api.invitations.updateInvitation(workspaceId, invitationId, data);
					return { data: invitation };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: 'Invitation' }]
		}),
		deleteInvitation: builder.mutation<number, InvitationParams>({
			queryFn: async (params: InvitationParams) => {
				const { workspaceId, invitationId } = params;
				try {
					await api.invitations.deleteInvitation(workspaceId, invitationId);
					return { data: invitationId };
				} catch (error) {
					return extractErrors(error);
				}
			},
			invalidatesTags: [{ type: 'Invitation' }]
		}),
	}),
});

export const {
	useGetInvitationsQuery,
	useUpdateInvitationMutation,
	useDeleteInvitationMutation,
} = invitationApi